<template>
  <Breadcrumb :urls="urls"  :translationloaded="translationloaded" :translate="translate"></Breadcrumb>
  <WhatsAppButton></WhatsAppButton>
  <div class="account-info-sec" v-if="translationloaded==true">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <div id="show-hidden-menu" class="dashBoarsBtnMain" @click="toggledashboard()">
            <i class="fa fa-th-large" aria-hidden="true"></i>{{translate('dashboard')}}
          </div>
          <div id ="vdasboard"   class="hidden-menu" style="display: none;">
               <SideBar
              :translationloaded="translationloaded"
              :translate="translate"
            ></SideBar>
          </div>
          <div class="mainSideDiplay">
               <SideBar
              :translationloaded="translationloaded"
              :translate="translate"
            ></SideBar>
          </div>
        </div>
        <div class="col-md-9">
          <div class="account-details-sec">
            <div class="row">
              <div class="col-md-12">
                <template
                  v-for="(coupon, index) in fetctCustomerCouponlist"
                  :key="index"
                >
                  <div class="coupon_box">
                    <div class="row">
                      <div class="col-md-8">
                        <h3>{{ coupon.cpAmount }}% {{translate('OFF')}}</h3>
                        <!-- <span class="coupon-text">Upto QR 0 OFF</span> -->
                        <p>
                          {{translate('minimumOrderAmount')}} {{currency}} {{ coupon.cpMinOrderAmount }}
                        </p>
                      </div>
                      <div class="col-md-4 couponsDivTxt">
                        <span
                          class="coupon-num"
                          @click="copyClipboard(coupon.cpCode)"
                          >{{ coupon.cpCode }}
                          <i class="fa fa-clone" aria-hidden="true"></i
                        ></span>
                        <span class="copounsTxtAll"
                          >{{translate('expires')}} {{ formatedDate(coupon.cpExpiry) }}</span
                        >
                        <br>
                        <a href="/checkout">{{translate('gotocheckout')}}</a>
                        
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="fetctCustomerCouponlist==''">
                  <p class="text-center">{{ translate('noOffersFound')}}</p>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "../components/Breadcrumb";
import SideBar from "../components/my_account/SideBar";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import helper from "@/helper/helper";
import WhatsAppButton from "@/components/WhatsAppButton";
import Config from "@/config";
export default {
  name: "MyCoupons",
  components: {
    Breadcrumb,
    SideBar,
    WhatsAppButton
  },
   props:[
     'translationloaded',
     'translate'
  ],
  data() {
    return {
      urls: [
        {
          path: "/",
          name: "Home",
        },

        {
          path: "/my-coupons",
          name: "myCoupons",
        },
      ],
      currency:''
    };
  },
  methods: {
     toggledashboard()
    {
       var x = document.getElementById("vdasboard");
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
    },
    ...mapActions(["getCustomerCouponList"]),
    formatedDate(date) {
      return moment(date).format("MMMM D, YYYY");
    },
    copyClipboard(couponCode) {
      const el = document.createElement("textarea");
      el.value = couponCode;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$toast.success(`${couponCode} Coupon code copied to clipboard`);
    },
  },
  mounted() {
    this.getCustomerCouponList();
     helper.backtoTop();
     this.currency =Config.CURRECNCY;
  },
  computed: {
    ...mapGetters(["fetctCustomerCouponlist"]),
  },
};
</script>
<style scoped>
.coupon-num {
  cursor: pointer;
}
</style>
